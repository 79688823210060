import React from 'react';

import { Box, Card, CardMedia, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCoverCardMediaStyles } from '@mui-treasury/styles/cardMedia/cover';

const useStyles = makeStyles({
  card: {
    borderRadius: '1rem',
    color: 'white',
    margin: '0 auto',
    minHeight: '40vw',
    position: 'relative',
    transition: '0.2s',
    minWidth: '35vw',
    '&:hover': {
      transform: 'scale(1.02)',
    },
    '&:after': {
      background: 'linear-gradient(to top, #000, rgba(0,0,0,0))',
      bottom: 0,
      content: '""',
      display: 'block',
      height: '100%',
      position: 'absolute',
      width: '100%',
      zIndex: 1,
    },
  },
  content: {
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    width: '100%',
  },
  link: {
    '&:visited': {
      color: 'white',
    },
  },
});

const LinkCard = ({ caption, href, imageUrl }) => {
  const classes = useStyles();
  const mediaStyles = useCoverCardMediaStyles({ bgPosition: 'top' });
  return (
    <Link className={classes.link} href={href} underline='none'>
      <Card className={classes.card} elevation={4}>
        <CardMedia classes={mediaStyles} image={imageUrl} />
        <Box p={2} className={classes.content}>
          <Typography variant='h4' component='h1'>
            {caption}
          </Typography>
        </Box>
      </Card>
    </Link>
  );
};

export { LinkCard };
export default LinkCard;
